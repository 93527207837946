import { useEffect } from 'react';
import { type UserProviderValue, UserStatus } from './UserProvider.types';
import * as Sentry from 'utils/sentry';

export function useSentryUser({ status, userData }: UserProviderValue) {
  useEffect(() => {
    if (status === UserStatus.FullyLoaded) {
      Sentry.setUser({
        id: userData.wordpressUserId.toString(),
        username: userData.wordpressUsername,
        email: userData.email,
      });
    }
  }, [userData, status]);
}
