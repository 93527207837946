import { type LinkProps, Link } from 'turnip/Link/Link';
import { supportUrl } from 'utils/carrotUrls';

/**
 * A link that inherits the color from its parent, so that we can easily drop
 * appropriately-styled links into components like Alert, BigAlert, and Banner,
 * that have different color variants.
 */
function ContextualLink(props: Omit<LinkProps, 'className'>) {
  return (
    <Link
      {...props}
      className="rounded-sm font-bold text-inherit underline focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-current"
    />
  );
}

/**
 * Convenience wrapper around ContextualLink that links to the support page.
 */
function SupportLink({
  children = 'contact support',
}: {
  children?: React.ReactNode;
}) {
  return (
    <ContextualLink to={supportUrl} target="_blank" rel="noreferrer">
      {children}
    </ContextualLink>
  );
}

export { ContextualLink, SupportLink };
