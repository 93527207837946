type CarrotEnvironment =
  | 'development'
  | 'integration'
  | 'staging'
  | 'production';
export function carrotEnvironment(domain: string) {
  const environments: { [key: string]: CarrotEnvironment } = {
    'carrot.pizza': 'development',
    'testcarrot.xyz': 'integration',
    'investorcarrots.com': 'staging',
    'carrot.com': 'production',
  };

  return environments[domain];
}
