import loadable from '@loadable/component';
import { CenteredPageLoadingCarrot } from 'turnip/LoadingCarrot/LoadingCarrot';

const baseLoadableOptions = { fallback: <Fallback /> };

const loadableOptions = (name: string) => ({
  ...baseLoadableOptions,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resolveComponent: (m: any) => m[name],
});

function Fallback() {
  return <CenteredPageLoadingCarrot />;
}

// Lazy-loaded components
const CreateAccount = loadable(
  () => import('pages/CreateAccount/CreateAccount'),
  loadableOptions('CreateAccount')
);
const CreateAccountComplete = loadable(
  () => import('pages/CreateAccount/Complete/CreateAccountComplete'),
  loadableOptions('CreateAccountComplete')
);
const OnboardingRoutes = loadable(
  () => import('pages/Onboarding/OnboardingRoutes'),
  loadableOptions('OnboardingRoutes')
);
const KeywordExplorerOrUpsell = loadable(
  () => import('pages/KeywordExplorer/KeywordExplorer'),
  loadableOptions('KeywordExplorerOrUpsell')
);
const MembershipManagement = loadable(
  () => import('pages/MembershipManagement/MembershipManagement'),
  loadableOptions('MembershipManagement')
);
const SignUp = loadable(() => import('pages/SignUp/SignUp'), {
  ...baseLoadableOptions,
  resolveComponent: (m) => m.SignUp,
});
const MembershipEditor = loadable(
  () => import('pages/MembershipEditor/MembershipEditor'),
  loadableOptions('MembershipEditor')
);
const Analytics = loadable(
  () => import('pages/Analytics/Analytics'),
  loadableOptions('Analytics')
);
const DomainOverview = loadable(
  () => import('pages/DomainOverview/DomainOverview'),
  loadableOptions('DomainOverview')
);
const BacklinkAnalysis = loadable(
  () => import('pages/BacklinkAnalysis/BacklinkAnalysis'),
  loadableOptions('BacklinkAnalysis')
);
const ContentProMigration = loadable(
  () => import('pages/ContentProMigration/ContentProMigration'),
  loadableOptions('ContentProMigration')
);
const ContentOverview = loadable(
  () => import('pages/ContentOverview/ContentOverview'),
  loadableOptions('ContentOverview')
);
const SearchPerformance = loadable(
  () => import('pages/SearchPerformance/SearchPerformance'),
  loadableOptions('SearchPerformance')
);
const SiteAuditRoutes = loadable(
  () => import('pages/SiteAudit/SiteAudit.routes'),
  loadableOptions('SiteAuditRoutes')
);
const SuperAuthorizedPage = loadable(
  () => import('pages/Super/SuperAuthorizedPage'),
  {
    ...baseLoadableOptions,
    resolveComponent: (m) => m.SuperAuthorizedPage,
  }
);
const SuperBundlesList = loadable(
  () => import('pages/Super/Bundles/List/List'),
  loadableOptions('List')
);
const SuperBundlesCreate = loadable(
  () => import('pages/Super/Bundles/Create/Create'),
  loadableOptions('Create')
);
const CarrotCrmOptIn = loadable(
  () => import('pages/CarrotCrmOptIn/CarrotCrmOptIn'),
  loadableOptions('CarrotCrmOptIn')
);
const TeamMembersRoutes = loadable(
  () => import('pages/TeamMembers/TeamMembers.routes'),
  loadableOptions('TeamMembersRoutes')
);
const AcceptInvite = loadable(
  () => import('pages/TeamMembers/AcceptInvite/AcceptInvite'),
  loadableOptions('AcceptInvite')
);
const SwitchAccount = loadable(
  () => import('pages/SwitchAccount/SwitchAccount'),
  loadableOptions('SwitchAccount')
);

export {
  AcceptInvite,
  Analytics,
  BacklinkAnalysis,
  CarrotCrmOptIn,
  ContentOverview,
  ContentProMigration,
  CreateAccount,
  CreateAccountComplete,
  DomainOverview,
  KeywordExplorerOrUpsell,
  MembershipEditor,
  MembershipManagement,
  OnboardingRoutes,
  SearchPerformance,
  SignUp,
  SiteAuditRoutes,
  SuperAuthorizedPage,
  SuperBundlesCreate,
  SuperBundlesList,
  SwitchAccount,
  TeamMembersRoutes,
};
