import CarrotIcon from 'turnip/assets/images/carrot-icon-main.svg?react';

type Props = {
  title?: string;
};

function LoadingCarrot({ title = 'Loading' }: Props) {
  return <CarrotIcon className="animate-pulse" title={title} role="img" />;
}

// INFO: This is offset vertically so that when the footer is visible the
// loader doesn't look awkwardly close to it (particularly on mobile).
function CenteredPageLoadingCarrot(props: Props) {
  return (
    <div className="absolute inset-0 -top-32 grid place-items-center">
      <LoadingCarrot {...props} />
    </div>
  );
}

export { CenteredPageLoadingCarrot, LoadingCarrot };
