import {
  responsiveClasses,
  type ResponsiveProp,
} from 'turnip/responsiveClasses';

type MarginSize =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 14
  | 16
  | 20
  | 24;
interface OffsetProps {
  top?: ResponsiveProp<MarginSize>;
  left?: ResponsiveProp<MarginSize>;
  children: React.ReactNode;
}

/**
 * Layout component used for adding margins to another component
 *
 * Because a component should not impact layout outside itself, the
 * outer element of a component should not have margins.
 *
 * Picking consistent margin directions is a helpful practice.
 * Our standard is to use top and left margins.
 *
 * NOTE: The corresponding TW classes must be safelisted in our config.
 */
export function Offset({ top = 0, left = 0, children }: OffsetProps) {
  const topClasses = responsiveClasses(top, responsiveTopMarginClassResolver);
  const leftClasses = responsiveClasses(
    left,
    responsiveLeftMarginClassResolver
  );

  return <div className={`${topClasses} ${leftClasses}`}>{children}</div>;
}

function responsiveTopMarginClassResolver(size: MarginSize) {
  return `mt-${size}`;
}

function responsiveLeftMarginClassResolver(size: MarginSize) {
  return `ml-${size}`;
}
