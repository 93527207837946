import { UserStatus } from 'context/UserProvider/UserProvider.types';
import { useUserContext } from 'context/UserProvider/useUserContext';
import { PageNotFound } from 'pages/PageNotFound/PageNotFound';
import { PropsWithChildren } from 'react';
import { useRouteError } from 'react-router-dom';
import { Alert } from 'turnip/Alert/Alert';
import { SupportLink } from 'turnip/ContextualLink/ContextualLink';
import { Offset } from 'turnip/Offset/Offset';
import { PageLayout } from 'turnip/PageLayout/PageLayout';
import { PageLayoutContained } from 'turnip/PageLayoutContained/PageLayoutContained';
import { PageTitle } from 'turnip/PageTitle/PageTitle';
import { PageWithAuthenticatedNavs } from 'turnip/PageWithAuthenticatedNavs/PageWithAuthenticatedNavs';
import { Stack } from 'turnip/Stack/Stack';

type ErrorPageProps = {
  singleSiteRoute?: boolean;
};

export function ErrorPage({ singleSiteRoute = false }: ErrorPageProps) {
  const error = useRouteError();
  const Layout = singleSiteRoute ? PageLayout : PageLayoutContained;

  if (isErrorWithStatus(error) && error.status === 404) {
    return (
      <MaybeWithAuthenticatedNavs singleSiteRoute={singleSiteRoute}>
        <PageNotFound contained={!singleSiteRoute} />
      </MaybeWithAuthenticatedNavs>
    );
  }

  return (
    <MaybeWithAuthenticatedNavs singleSiteRoute={singleSiteRoute}>
      <Layout>
        <Stack gap={4}>
          <PageTitle>Something went wrong</PageTitle>

          <Alert variant="error" headline="An error occurred">
            Something went wrong while processing your request. Please try again
            or <SupportLink />.
          </Alert>
        </Stack>
      </Layout>
    </MaybeWithAuthenticatedNavs>
  );
}

function MaybeWithAuthenticatedNavs({
  singleSiteRoute,
  children,
}: PropsWithChildren<ErrorPageProps>) {
  const { status } = useUserContext();

  if (status === UserStatus.FullyLoaded) {
    return (
      <PageWithAuthenticatedNavs showSingleSiteNav={singleSiteRoute}>
        {children}
      </PageWithAuthenticatedNavs>
    );
  }

  return <Offset top={24}>{children}</Offset>;
}

type ErrorWithStatus = {
  status: number;
};
const isErrorWithStatus = (error: unknown): error is ErrorWithStatus =>
  typeof error === 'object' &&
  !!error &&
  'status' in error &&
  typeof error.status === 'number';
