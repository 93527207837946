import cx from 'clsx';
import type { FullyLoadedUserProviderData } from 'context/UserProvider/UserProvider.types';
import {
  Submenu,
  type SubmenuButtonProps,
  SubmenuItem,
} from 'turnip/AuthenticatedNavBar/Submenu/Submenu';
import { appUrl, routes } from 'utils/carrotUrls';
import { submenuButtonClasses } from '../Submenu/classes';

export function UserMenu({
  avatarUrl,
}: {
  avatarUrl: FullyLoadedUserProviderData['userData']['avatarUrl'];
}) {
  return (
    <Submenu
      button={(props) => <Button avatarUrl={avatarUrl} {...props} />}
      position="bottom-right"
      widthClass="w-48"
    >
      <SubmenuItem href={`${appUrl}/account/`}>Account</SubmenuItem>
      <SubmenuItem href={`${appUrl}/account/profile/`}>Profile</SubmenuItem>
      <SubmenuItem href={routes().billingHome}>Billing</SubmenuItem>
      <SubmenuItem href={`${appUrl}/wp-login.php?action=logout&amui_logout=1`}>
        Log Out <LogOutIcon />
      </SubmenuItem>
    </Submenu>
  );
}

function Button({
  avatarUrl,
  open,
  innerRef,
  ...props
}: {
  avatarUrl: FullyLoadedUserProviderData['userData']['avatarUrl'];
} & SubmenuButtonProps) {
  const classes = cx(
    submenuButtonClasses(open),
    "pr-7 after:absolute after:right-2 after:text-2xl after:content-['▾'] md:pr-8 md:after:right-3"
  );

  return (
    <button ref={innerRef} className={classes} {...props}>
      <span className="sr-only">Open user menu</span>
      {avatarUrl && (
        <img
          src={avatarUrl}
          alt=""
          width="34"
          height="34"
          loading="lazy"
          className="h-[38px] w-[38px] max-w-none rounded-full border-2 border-white"
        />
      )}
    </button>
  );
}

function LogOutIcon() {
  return (
    <svg
      width="15"
      height="13"
      viewBox="-2 -2 28 25"
      xmlns="http://www.w3.org/2000/svg"
      className="ml-0.5 inline-block text-orange-700"
    >
      <path
        d="M13 17v4H0V0h13v4m-8 7h18m-5-5l5 5-5 5"
        stroke="currentColor"
        strokeWidth="2.5"
        fill="none"
      />
    </svg>
  );
}
