import type { QueryClientConfig } from '@tanstack/react-query';

const FIVE_MINUTES_IN_MS = 1000 * 60 * 5;
export const queryClientConfig = {
  defaultOptions: {
    queries: {
      staleTime: FIVE_MINUTES_IN_MS,
    },
  },
} satisfies QueryClientConfig;
