import { useQuery } from '@tanstack/react-query';
import { jwtDecode } from 'jwt-decode';
import { useEffect } from 'react';
import { authenticationEndpoint } from 'utils/carrotUrls';
import {
  UserStatus,
  type CarrotTokenPayload,
  type SetUserProviderDataArg,
  type UserProviderValue,
} from './UserProvider.types';

/**
 * Check if the user is logged in to WordPress and update the UserProvider value
 */
export function useCarrotUser(userProviderValue: UserProviderValue) {
  const { data: token, isPending } = useJwt();

  useEffect(() => {
    const { status, setUserProviderData } = userProviderValue;

    if (status === UserStatus.NotChecked) {
      setUserProviderData({ status: UserStatus.Loading });
    }

    if (status === UserStatus.Loading && !isPending) {
      setUserProviderData(userDataFromToken(token));
    }
  }, [userProviderValue, token, isPending]);
}

function userDataFromToken(token: string | null): SetUserProviderDataArg {
  if (!token) {
    return { status: UserStatus.LoggedOut };
  }

  const { id, avatarUrl } = jwtDecode<CarrotTokenPayload>(token).data.user;
  return {
    token,
    status: UserStatus.LoggedIn,
    userData: {
      id,
      avatarUrl,
    },
  };
}

function useJwt() {
  return useQuery({
    queryKey: ['jwt'],
    queryFn: async () => {
      const response = await fetch(authenticationEndpoint, {
        credentials: 'include',
      });
      return response.json();
    },
    select: (data) => data?.token ?? null,
    staleTime: Infinity,
  });
}
