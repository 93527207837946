import cx from 'clsx';
import { type Gap, gapClassResolver } from 'turnip/classResolvers';
import {
  responsiveClasses,
  type ResponsiveProp,
} from 'turnip/responsiveClasses';

type Direction = 'horizontal' | 'vertical';

interface StackProps {
  children: React.ReactNode;
  /** Direction of the items */
  direction?: ResponsiveProp<Direction>;
  /** Size of the gap between items */
  gap?: ResponsiveProp<Gap>;
  /** If the stack should wrap */
  wrap?: ResponsiveProp<boolean>;
  /** Alignment of children along the main axis */
  justifyContent?: ResponsiveProp<ContentJustification>;
  /** Alignment of children along the cross axis */
  alignItems?: ResponsiveProp<ItemAlignment>;
}

/**
 * Render a horizontal or vertical set of items with even spacing
 */
export function Stack({
  children,
  direction = 'vertical',
  gap,
  wrap = false,
  justifyContent = 'flex-start',
  alignItems = 'stretch',
}: StackProps) {
  const classes = cx(
    'flex',
    responsiveClasses(wrap, wrapClassResolver),
    responsiveClasses(direction, directionClassResolver),
    responsiveClasses(gap, gapClassResolver),
    responsiveClasses(justifyContent, contentJustificationClassResolver),
    responsiveClasses(alignItems, itemAlignmentClassResolver)
  );

  return (
    <div className={classes} data-testid="stack">
      {children}
    </div>
  );
}

function wrapClassResolver(wrap: boolean) {
  return wrap ? 'flex-wrap' : 'flex-nowrap';
}

/**
 * Return the needed Tailwind flex direction class
 *
 * NOTE: the classes (including responsive variants) must be safelisted in TW
 */
function directionClassResolver(direction: Direction) {
  return {
    vertical: 'flex-col',
    horizontal: 'flex-row',
  }[direction];
}

const justifyContentClasses = {
  'flex-start': 'justify-start',
  'flex-end': 'justify-end',
  center: 'justify-center',
  'space-between': 'justify-between',
  'space-around': 'justify-around',
  'space-evenly': 'justify-evenly',
};
type ContentJustification = keyof typeof justifyContentClasses;
function contentJustificationClassResolver(
  justification: ContentJustification
) {
  return justifyContentClasses[justification];
}

const alignItemsClasses = {
  'flex-start': 'items-start',
  'flex-end': 'items-end',
  center: 'items-center',
  baseline: 'items-baseline',
  stretch: 'items-stretch',
};
type ItemAlignment = keyof typeof alignItemsClasses;
function itemAlignmentClassResolver(alignment: ItemAlignment) {
  return alignItemsClasses[alignment];
}
