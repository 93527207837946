import type { CustomerType } from 'types/Users';
import type { Maybe } from 'types/Utilities';

function customerTypeMatches(
  userCustomerType: Maybe<CustomerType>,
  matchCustomerType: CustomerType
) {
  if (!userCustomerType) return false;

  return (
    userCustomerType === matchCustomerType || matchCustomerType === 'hybrid'
  );
}

function hasCurrentSubscription(carrotAccountStatus: string): boolean {
  return [
    'active',
    'active_past_due',
    'suspended_past_due',
    'trialing',
  ].includes(carrotAccountStatus);
}

export { customerTypeMatches, hasCurrentSubscription };
