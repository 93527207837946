/**
 * Navigate the user
 * We use this instead of directly calling window.location.assign so that we
 * can spy on it in stories.
 */
export function assignLocation(url: string) {
  if (window.carrot.preventTestNavigation) {
    return;
  }

  window.location.assign(url);
}
