import { Alert } from 'turnip/Alert/Alert';
import { SupportLink } from 'turnip/ContextualLink/ContextualLink';
import { CenteredPageLoadingCarrot } from 'turnip/LoadingCarrot/LoadingCarrot';
import { PageLayoutContained } from 'turnip/PageLayoutContained/PageLayoutContained';
import { PageWithAuthenticatedNavs } from 'turnip/PageWithAuthenticatedNavs/PageWithAuthenticatedNavs';
import { useCarrotUser } from './useCarrotUser';
import { useIntercomUser } from './useIntercomUser';
import { UserStatus } from './UserProvider.types';
import { useSentryUser } from './useSentryUser';
import { useUserContext } from './useUserContext';
import { useUserData } from './useUserData.am';

function GetCurrentUser({ children }: { children: React.ReactNode }) {
  const userProviderValue = useUserContext();

  useCarrotUser(userProviderValue);
  useUserData(userProviderValue);
  useIntercomUser(userProviderValue);
  useSentryUser(userProviderValue);

  switch (userProviderValue.status) {
    case UserStatus.NotChecked:
    case UserStatus.Loading:
    case UserStatus.LoggedIn: {
      return <AuthenticationLoading />;
    }

    case UserStatus.UserQueryError: {
      return (
        <PageWithAuthenticatedNavs>
          <PageLayoutContained>
            <Alert
              variant="error"
              headline="Something went wrong"
              constrained={false}
            >
              Please try again or <SupportLink />.
            </Alert>
          </PageLayoutContained>
        </PageWithAuthenticatedNavs>
      );
    }

    case UserStatus.LoggedOut:
    case UserStatus.FullyLoaded: {
      return <>{children}</>;
    }
  }
}

function AuthenticationLoading() {
  return <CenteredPageLoadingCarrot />;
}

export { AuthenticationLoading, GetCurrentUser };
