import type { Maybe } from 'types/Utilities';
import { supportUrl } from './carrotUrls';

/**
 * Return the provided link and text or the URL of the support page and the text 'Contact Support'
 * @typedef {string} LinkHref - primaryHref or the support URL
 * @typedef {string} LinkText - primaryText or 'Contact Support'
 * @returns [LinkHref, LinkText]
 */
export function linkOrSupportLink(
  primaryHref: Maybe<string>,
  primaryText: string,
  supportText = 'Contact Support'
): [string, string] {
  return [primaryHref ?? supportUrl, primaryHref ? primaryText : supportText];
}
