import { useScript } from '#Root/ThirdPartyScriptLoader/useScript';

export function useGoogleAnalyticsScript(googleAnalyticsId: string) {
  // Load Google tag (gtag.js)
  useScript({
    src: `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`,
  });

  // Set up data layer
  const body = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', '${googleAnalyticsId}', { send_page_view: false });
  `;
  useScript({ body });
}
