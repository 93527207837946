import browserUpdate from 'browser-update';
import isChromatic from 'chromatic/isChromatic';
import { useEffect, useRef } from 'react';

/**
 * Display a banner to users with outdated browsers.
 * @see https://help.carrot.com/tutorial/supported-browsers/
 */
export function BrowserUpdate() {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current && !isChromatic()) {
      browserUpdate({
        required: {
          e: -2,
          f: -2,
          o: -2,
          s: -2,
          c: -2,
        },
        insecure: true,
        unsupported: true,
        api: 2024.04,
        reminder: 0,
        reminderClosed: 150,
        no_permanent_hide: true,
        shift_page_down: false,
        container: containerRef.current,
      });
    }
  }, []);

  return <div ref={containerRef}></div>;
}
