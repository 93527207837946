import { useScript } from '#Root/ThirdPartyScriptLoader/useScript';

export const useSegmetricsScript = () => {
  useScript({
    id: 'segmetrics',
    body: `
      var _segq = _segq || [];
      var _segs = _segs || {};
      (function () {var dc = document.createElement('script');dc.type = 'text/javascript';dc.async = true;dc.src = '//tag.segmetrics.io/a1oJNd.js';var s = document.getElementsByTagName('script')[0];s.parentNode.insertBefore(dc,s);})();
    `,
  });
};
