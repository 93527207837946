import { useEffect } from 'react';

export function useScript({
  id,
  src,
  body,
  async,
}: {
  /** script element id */
  id?: string;
  /** Provide only one of src or body */
  src?: string;
  /** Provide only one of src or body */
  body?: string;
  /** Defaults to true when src is provided, otherwise false */
  async?: boolean;
}) {
  useEffect(() => {
    const script = document.createElement('script');

    if (id) {
      script.id = id;
      script.dataset.testid = id;
    }
    if (src) script.src = src;
    if (!src && body) script.innerHTML = body;

    script.async = async ?? !!src;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [async, body, id, src]);
}
