import {
  Submenu,
  type SubmenuButtonProps,
  SubmenuItem,
} from 'turnip/AuthenticatedNavBar/Submenu/Submenu';
import { submenuButtonClasses } from '../Submenu/classes';
import CarrotIcon from 'turnip/assets/images/carrot-icon-white.svg?react';

function Button({ open, innerRef, ...props }: SubmenuButtonProps) {
  return (
    <button ref={innerRef} className={submenuButtonClasses(open)} {...props}>
      <span className="sr-only">Open main menu</span>
      <CarrotIcon className="h-[34px]" />
    </button>
  );
}

export function MainMenu({
  items,
}: {
  items: Array<{ name: string; url: string }>;
}) {
  return (
    <Submenu button={(props) => <Button {...props} />}>
      {items.map(({ name, url }) => (
        <SubmenuItem key={url} href={url}>
          {name}
        </SubmenuItem>
      ))}
    </Submenu>
  );
}
