import { termsUrl, privacyPolicyUrl } from 'utils/carrotUrls';
import CarrotIcon from 'turnip/assets/images/carrot-icon-white.svg?react';
import { Stack } from 'turnip/Stack/Stack';
import { Offset } from 'turnip/Offset/Offset';

export function Footer() {
  return (
    <footer className="bg-[#1a202c] px-5 py-12 text-center text-white md:text-left">
      <div className="mx-auto max-w-[1170px]">
        <Stack
          direction={{ base: 'vertical', md: 'horizontal' }}
          gap={{ base: 0, md: 5 }}
          alignItems={{ base: 'center', md: 'flex-start' }}
        >
          <FooterLogo />

          <FooterText />
        </Stack>
      </div>
    </footer>
  );
}

function FooterLogo() {
  return (
    <div>
      <CarrotIcon title="Carrot logo" className="h-auto md:w-12" />
    </div>
  );
}

function FooterText() {
  const year = new Date().getFullYear();

  return (
    <Offset top={3}>
      <p className="text-sm md:text-base">
        Lead-Generating Real Estate and Home Services Contractors Websites ©{' '}
        <span data-chromatic="ignore">{year}</span> Carrot, LLC – All Rights
        Reserved
      </p>

      <Offset top={{ base: 2, md: 0 }}>
        <Stack
          direction="horizontal"
          gap={4}
          justifyContent={{ base: 'center', md: 'flex-start' }}
        >
          <FooterLink href={termsUrl}>Terms</FooterLink>
          <FooterLink href={privacyPolicyUrl}>Privacy</FooterLink>
        </Stack>
      </Offset>
    </Offset>
  );
}

interface FooterLinkProps {
  children: React.ReactNode;
  href: string;
}
function FooterLink({ children, ...props }: FooterLinkProps) {
  return (
    <a
      className="text-xs font-bold text-[#bbb] transition-colors hover:text-white md:text-sm"
      {...props}
    >
      {children}
    </a>
  );
}
