/**
 * Return the base URL for account-manager for the current environment.
 *
 * This is set at build time in environments where it
 * can't be derived from the Carrot domain.
 *
 * @param {string} carrotDomain - Carrot domain for the current env
 * @returns {string} Base account-manager URL for the current environment
 */
export function accountManagerBaseUrl(carrotDomain: string): string {
  const urlFromEnv = import.meta.env.VITE_ACCOUNT_MANAGER_URL;
  if (urlFromEnv) {
    return urlFromEnv;
  }

  return `https://api-v2.${carrotDomain}`;
}
