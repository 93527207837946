import type React from 'react';
import { Stack } from 'turnip/Stack/Stack';
import { carrotDomain } from 'utils/carrotDomain';
import { MainMenu } from './MainMenu/MainMenu';
import CarrotIcon from 'turnip/assets/images/carrot-icon-white.svg?react';
import PhoneIcon from 'turnip/assets/images/phone-icon.svg?react';
import BellIcon from 'turnip/assets/images/bell-icon.svg?react';
import { UserMenu } from './UserMenu/UserMenu';
import {
  appUrl,
  communityUrl,
  contentUrl,
  helpUrl,
  marketplaceUrl,
} from 'utils/carrotUrls';
import { isUrl } from 'turnip/Link/isUrl';
import { NavLink as OriginalNavLink } from 'react-router-dom';
import clsx from 'clsx';
import type { FullyLoadedUserProviderData } from 'context/UserProvider/UserProvider.types';
import { backgroundClasses } from './backgroundClasses';
import { AccountSwitcher } from './AccountSwitcher/AccountSwitcher';

export function AuthenticatedNavBar({
  accounts,
  avatarUrl,
  carrotCrmUrl,
}: {
  accounts: FullyLoadedUserProviderData['userData']['accounts'];
  avatarUrl: FullyLoadedUserProviderData['userData']['avatarUrl'];
  carrotCrmUrl?: string;
}) {
  const domain = carrotDomain();
  const accountUrl = `${appUrl}/account/`;
  const mainItems = menuItems(domain);

  const secondaryItems: NavItems = [
    { name: 'Keyword Explorer', url: '/keyword-explorer' },
    {
      name: 'Community',
      url: communityUrl,
    },
  ];
  if (carrotCrmUrl) {
    secondaryItems.unshift({ name: 'Carrot CRM', url: carrotCrmUrl });
  }

  const mobileItems = [
    { name: 'Account', url: accountUrl },
    ...secondaryItems,
    ...mainItems,
  ];

  return (
    <nav
      className="flex h-[50px] justify-between bg-orange-500 text-white md:h-[55px]"
      aria-label="General navigation"
    >
      <Stack direction="horizontal">
        <AccountLink accountUrl={accountUrl} />
        <MobileMenu items={mobileItems} />
        {Number(accounts?.length) >= 2 && (
          <AccountSwitcher accounts={accounts} />
        )}
        <SitesLink accountUrl={accountUrl} />
      </Stack>

      <Stack direction="horizontal">
        <MainNavItems items={secondaryItems} visibleBreakpoint="lg" />
        <MainNavItems items={mainItems} visibleBreakpoint="md" />
        <PhoneSupportLink domain={domain} />
        <ProductUpdatesLink />
        <UserMenu avatarUrl={avatarUrl} />
      </Stack>
    </nav>
  );
}

type NavItems = Array<{ name: string; url: string; condition?: boolean }>;

interface NavLinkProps extends React.ComponentPropsWithoutRef<'a'> {
  children: React.ReactNode;
  screenReaderText?: string;
  href: string;
}
function NavLink({
  href,
  children,
  title,
  id,
  screenReaderText,
}: NavLinkProps) {
  const linkProps = {
    title,
    id,
    className: `flex flex-1 flex-col justify-center whitespace-nowrap px-2 text-white md:px-3 ${backgroundClasses()}`,
  };
  const linkContent = (
    <>
      {screenReaderText && <span className="sr-only">{screenReaderText}</span>}
      {children}
    </>
  );

  if (isUrl(href)) {
    return (
      <a href={href} {...linkProps}>
        {linkContent}
      </a>
    );
  }

  return (
    <OriginalNavLink
      to={href}
      {...linkProps}
      className={({ isActive }) =>
        clsx(linkProps.className, isActive && 'underline underline-offset-4')
      }
    >
      {linkContent}
    </OriginalNavLink>
  );
}

function AccountLink({ accountUrl }: { accountUrl: string }) {
  return (
    <div className="hidden md:flex">
      <NavLink href={accountUrl} screenReaderText="Visit account">
        <CarrotIcon className="h-[39px]" />
      </NavLink>
    </div>
  );
}

function MobileMenu({ items }: { items: NavItems }) {
  return (
    <div className="flex md:hidden">
      <MainMenu items={items} />
    </div>
  );
}

function SitesLink({ accountUrl }: { accountUrl: string }) {
  return (
    <div className="flex flex-col justify-center border-l-2 border-solid border-[#ee8408]">
      <NavLink href={accountUrl}>
        <span className="text-lg font-bold md:text-[22px]">Sites</span>
      </NavLink>
    </div>
  );
}

const visibleClasses = {
  md: 'md:flex',
  lg: 'lg:flex',
};
function MainNavItems({
  items,
  visibleBreakpoint,
}: {
  items: NavItems;
  visibleBreakpoint: keyof typeof visibleClasses;
}) {
  return (
    <div className={`hidden ${visibleClasses[visibleBreakpoint]}`}>
      {items.map(({ name, url }) => (
        <NavLink key={url} href={url}>
          {name}
        </NavLink>
      ))}
    </div>
  );
}

function PhoneSupportLink({ domain }: { domain: string }) {
  const assistiveText = 'Schedule a support call';

  return (
    <div className="hidden md:flex">
      <NavLink
        href={`https://help.${domain}/call/`}
        title={assistiveText}
        screenReaderText={assistiveText}
      >
        <PhoneIcon />
      </NavLink>
    </div>
  );
}

function ProductUpdatesLink() {
  return (
    <NavLink
      href="https://app.getbeamer.com/carrot/en"
      id="nav-bar-carrot-updates"
      screenReaderText="View product updates"
    >
      <BellIcon />
    </NavLink>
  );
}

function menuItems(domain: string): NavItems {
  const mainItems: NavItems = [
    { name: 'Content Marketing', url: contentUrl },
    { name: 'Trainings', url: `https://members.${domain}` },
    { name: 'Marketplace', url: marketplaceUrl },
    { name: 'Help', url: helpUrl },
  ];

  return mainItems;
}
