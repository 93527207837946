import { QueryClient } from '@tanstack/react-query';
import { createRoutes } from 'AppRoutes';
import 'index.css';
import { initializeSentry } from 'initializeSentry';
import { queryClientConfig } from 'queryClientConfig';
import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { googleAnalyticsId, intercomId } from 'utils/nonSecrets';

initializeSentry();

const queryClient = new QueryClient(queryClientConfig);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider
      router={createBrowserRouter(
        createRoutes({ queryClient, googleAnalyticsId, intercomId })
      )}
    />
  </React.StrictMode>
);
