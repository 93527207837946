import { carrotDomain } from './carrotDomain';
import { carrotEnvironment } from './carrotEnvironment';

// INFO: Just a few bits that aren't sensitive secrets and aren't worth the
// effort to put into an actual environment variable.

const environment = carrotEnvironment(carrotDomain());
const isProd = environment === 'production';

const googleAnalyticsId = isProd ? 'G-830E7NLPFF' : 'G-8XNS2WRE4E';
const salesSiteAnalyticsId = isProd ? 'G-PREMB87DZ1' : 'G-T3Q61F9EJ1';
const intercomId = isProd ? 'jtyy30h5' : 'jt1cn2kj';

export { googleAnalyticsId, salesSiteAnalyticsId, intercomId };
