import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function usePageViewTracking() {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag && window.dataLayer) {
      window.gtag('set', 'page_path', location.pathname + location.search);
      window.gtag('event', 'page_view');
    }
  }, [location]);
}
