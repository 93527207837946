/** Tailwind breakpoints; update if config changes */
type Breakpoints = 'base' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

/** Generic for mapping breakpoints to values, used with ResponsiveProp */
type ResponsiveValues<Type> = {
  [Key in Breakpoints]?: Type;
};

/** Generic for accepting a prop that can be a Type or a responsive set of Type values */
type ResponsiveProp<Type> = Type | ResponsiveValues<Type>;

/** Generic for a function that takes a value and returns a classname for it */
type ClassResolver<Type> = (value: Type) => string;

/**
 * Generic function for returning a list of responsive classes for a responsive prop
 *
 * NOTE: You must either safelist the possible generated classes or manually
 * type them somewhere, or they will not exist in the Tailwind output.
 *
 * @example responsiveClasses(responsiveWidth, widthClassResolver)
 */
function responsiveClasses<Type>(
  responsiveProp: ResponsiveProp<Type> | null | undefined,
  classResolver: ClassResolver<Type>
) {
  if (responsiveProp === null || responsiveProp === undefined) return '';

  if (
    typeof responsiveProp === 'string' ||
    typeof responsiveProp === 'number' ||
    typeof responsiveProp === 'boolean'
  ) {
    return classResolver(responsiveProp);
  }

  return Object.entries(responsiveProp)
    .map(([breakpoint, value]) => {
      const classname = classResolver(value);
      return breakpoint === 'base' ? classname : `${breakpoint}:${classname}`;
    })
    .join(' ');
}

export type { ResponsiveProp };
export { responsiveClasses };
