import { Heading } from 'turnip/Heading/Heading';
import { Stack } from 'turnip/Stack/Stack';
import { Button, ButtonProps } from 'turnip/Button/Button';
import type { Maybe } from 'types/Utilities';

interface PageTitleProps {
  children: React.ReactNode;
  addendum?: Maybe<string>;
  buttonProps?: ButtonProps;
}

function PageTitle({ children, addendum, buttonProps }: PageTitleProps) {
  const title = (
    <Heading level={1} size="3xl">
      {addendum ? (
        <>
          {children}:{' '}
          <span className="block break-all text-base font-normal lg:inline lg:break-normal lg:text-3xl">
            {addendum}
          </span>
        </>
      ) : (
        children
      )}
    </Heading>
  );

  if (!buttonProps) {
    return title;
  }

  return (
    <Stack
      direction={{ base: 'vertical', md: 'horizontal' }}
      justifyContent="space-between"
      gap={4}
    >
      {title}

      {buttonProps && (
        <div className="flex items-start">
          <Button {...buttonProps} variant="primary" size="sm" />
        </div>
      )}
    </Stack>
  );
}

export type { PageTitleProps };
export { PageTitle };
