import type { Maybe } from './Utilities';

/** Carrot customer types */
type CustomerType = 'agent' | 'investor' | 'hybrid';

/** Subscription intervals */
type Interval = 'month' | 'year';

function isCustomerType(
  customerType: Maybe<string>
): customerType is CustomerType {
  return (
    !!customerType && ['agent', 'investor', 'hybrid'].includes(customerType)
  );
}

function isInterval(interval: Maybe<string>): interval is Interval {
  return !!interval && ['month', 'year'].includes(interval);
}

export type { CustomerType, Interval };
export { isCustomerType, isInterval };
