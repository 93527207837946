/**
 * Return the base Carrot domain for the current environment
 *
 * Example: testcarrot.xyz
 */
export function carrotDomain(): string {
  const hostname = window.location.hostname;
  // NOTE: we only expect to not have `my.` in the hostname in test environments
  const subdomainRegex = /^my\./;
  return hostname.match(subdomainRegex)
    ? hostname.replace(subdomainRegex, '')
    : 'carrot.pizza';
}
