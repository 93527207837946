import cx from 'clsx';
import { backgroundClasses } from '../backgroundClasses';

/** Starter classes for the typical submenu button appearance */
function submenuButtonClasses(open: boolean) {
  return cx(
    'relative flex min-h-full items-center px-2 py-[6px] text-white md:px-3',
    backgroundClasses(open)
  );
}

function itemLinkClasses(active: boolean) {
  return cx(
    'block whitespace-nowrap px-3 py-2 hover:text-orange-700',
    active ? 'text-orange-700' : 'text-gray-900'
  );
}

export { submenuButtonClasses, itemLinkClasses };
