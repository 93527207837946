import { useEffect } from 'react';
import { customerTypeMatches } from 'utils/users';
import { type UserProviderValue, UserStatus } from './UserProvider.types';

export function useIntercomUser({ status, userData }: UserProviderValue) {
  useEffect(() => {
    if (status === UserStatus.FullyLoaded && window.Intercom) {
      const isHybrid = userData.customerType === 'hybrid';
      window.Intercom('update', {
        user_id: userData.wordpressUsername,
        Investor:
          customerTypeMatches(userData.customerType, 'investor') || isHybrid,
        Agent: customerTypeMatches(userData.customerType, 'agent') || isHybrid,
        email: userData.email,
        name: userData.name,
        created_at: userData.createdAt.valueOf() / 1000,
        user_hash: userData.intercomUserHash,
        last_request_at: Math.floor(Date.now() / 1000),
        Membership:
          userData.currentSubscription?.items[0]?.price.wordpressLabel,
      });
    }
  }, [userData, status]);
}
