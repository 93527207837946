import type { OverridedMixpanel } from '#mixpanel';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { MixpanelProvider } from 'context/MixpanelProvider/MixpanelProvider';
import { UserProvider } from 'context/UserProvider/UserProvider';
import { useEffect } from 'react';
import { ScrollRestoration } from 'react-router-dom';
import { Notifications } from 'turnip/Notifications/Notifications';
import { ThirdPartyScriptLoader } from './ThirdPartyScriptLoader/ThirdPartyScriptLoader';

export type RootProps = {
  queryClient: QueryClient;
  mixpanel?: OverridedMixpanel;
  googleAnalyticsId: string;
  intercomId: string;
};

export function Root({
  queryClient,
  mixpanel,
  googleAnalyticsId,
  intercomId,
  children,
}: RootProps & { children: React.ReactNode }) {
  // Initialize carrot global namespace; we occasionally stick something useful
  // for tests in here
  useEffect(() => {
    window.carrot = window.carrot ?? {};
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <MixpanelProvider mixpanel={mixpanel}>
        <UserProvider>
          <ThirdPartyScriptLoader
            googleAnalyticsId={googleAnalyticsId}
            intercomId={intercomId}
          />

          {children}

          <Notifications />
        </UserProvider>
      </MixpanelProvider>

      <ScrollRestoration />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
