import { subject } from '@casl/ability';
import { Can } from 'context/UserProvider/Can';
import { AuthenticationLoading } from 'context/UserProvider/GetCurrentUser';
import { useUserContext } from 'context/UserProvider/useUserContext';
import {
  type FullyLoadedUserProviderData,
  UserStatus,
} from 'context/UserProvider/UserProvider.types';
import { PageNotFound } from 'pages/PageNotFound/PageNotFound';
import { Navigate, useMatch } from 'react-router-dom';
import { PageWithAuthenticatedNavs } from 'turnip/PageWithAuthenticatedNavs/PageWithAuthenticatedNavs';
import { loginUrl, routes } from 'utils/carrotUrls';
import { useCurrentUrl } from 'utils/useCurrentUrl';

/**
 * If the user is logged out, redirect them to log in. If the user is logged
 * in, restrict access to the page based on their account status and
 * permissions.
 */
export function AuthorizedPage({ children }: { children: React.ReactNode }) {
  // Status should be fully loaded or logged out at this point, as the
  // UserProvider wouldn't render this far down the tree otherwise
  const { status, userData } = useUserContext();
  const singleSiteRouteMatch = useMatch({
    path: '/account/site/:siteId',
    end: false,
  });
  const currentUrl = useCurrentUrl();

  if (status !== UserStatus.FullyLoaded) {
    window.location.assign(loginUrl(currentUrl));
    return <AuthenticationLoading />;
  }

  const siteId = singleSiteRouteMatch?.params.siteId;
  return (
    <RestrictByAccountStatus
      userData={userData}
      onSingleSiteRoute={!!singleSiteRouteMatch}
    >
      {siteId ? (
        <RestrictBySiteAccess siteId={siteId}>{children}</RestrictBySiteAccess>
      ) : (
        children
      )}
    </RestrictByAccountStatus>
  );
}

/**
 * Render the provided children if the user has the necessary account status.
 */
function RestrictByAccountStatus({
  userData: { superAdmin, carrotAccountStatus },
  onSingleSiteRoute,
  children,
}: {
  userData: FullyLoadedUserProviderData['userData'];
  onSingleSiteRoute: boolean;
  children: React.ReactNode;
}) {
  // Users without an active subscription have restricted access.
  const userHasAllowedStatus = [
    'active',
    'active_past_due',
    'trialing',
  ].includes(carrotAccountStatus);

  /**
   * Certain routes are exceptions that should be accessible to all users,
   * however, regardless of if they have an allowed status.
   *
   * The billing route is allowed because a) all current and former users
   * should be able to access their old invoices, etc., and b) we send users
   * who need to reactivate or resolve other account problems there.
   *
   * The onboarding pages are allowed because a) it should be harmless, and b)
   * sometimes subscription data hasn't been synced yet after sign-up
   * (typically for bundle sign-ups). This caused a bug where the user was
   * redirected to the billing home page instead of being sent to the
   * onboarding flow.
   *
   * The single-site route is allowed for users lacking an allowed account
   * status because users who are added to a site by a Carrot customer do not
   * themselves have a Carrot account. Blocking them would prevent them from
   * accessing the features they should be able to use.
   */
  const billingRouteMatch = useMatch(routes().billingHome);
  const onboardingRouteMatch = useMatch({ path: '/onboarding/', end: false });
  const onExceptionRoute =
    onSingleSiteRoute || billingRouteMatch || onboardingRouteMatch;

  // Redirect users who lack an allowed account status and are not accessing
  // one of the exception routes to the billing page.
  if (!superAdmin && !userHasAllowedStatus && !onExceptionRoute) {
    return <Navigate to={routes().billingHome} />;
  }

  return <>{children}</>;
}

function RestrictBySiteAccess({
  siteId,
  children,
}: {
  siteId: string;
  children: React.ReactNode;
}) {
  const wpBlog = subject('WpBlog', {
    wordpress_blog_id: parseInt(siteId),
  });

  return (
    <>
      <Can I="read" this={wpBlog}>
        {children}
      </Can>

      <Can not I="read" this={wpBlog}>
        <PageWithAuthenticatedNavs>
          <PageNotFound />
        </PageWithAuthenticatedNavs>
      </Can>
    </>
  );
}
